import fullpage from "fullpage.js";
import { gsap } from "gsap/all";
import { routeCicle } from "./animation/routeCircle";
function isIe() {
  return getInternetExplorerVersion() !== -1;
}

function isSafari() {
  return !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
}

function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
}

function isIOs() {
  return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
}
const toggleVisible = function(node, flag) {
  node.classList.toggle("ihidden", flag);
};
const ballon = document.getElementById("ballon");
const messages = document.getElementsByClassName("trip__message");
const messages_timeline = gsap
  .timeline({ paused: true })
  .to(messages[0], { opacity: 100, duration: 1 })
  .to(messages[1], { opacity: 100, duration: 1 })
  .to(messages[2], { opacity: 100, duration: 1 })
  .to(messages[3], { opacity: 100, duration: 1 })
  .to(messages[4], { opacity: 100, duration: 1 })
  .to(messages[5], { opacity: 100, duration: 1 });

const left_cloud_3 = document.getElementById("left-cloud-3");
const right_cloud_3 = document.getElementById("right-cloud-3");
//4
const routeCircleAnimation = new routeCicle();
//5
const sun_5 = document.getElementById("sun-5");
const sun_animation = gsap.to(sun_5, {
  rotation: 360,
  transformOrigin: "50% 50%",
  duration: 10,
  paused: true
});
function tooglePhone(phone) {
  const phones = ["phone1", "phone2", "phone3", "phone4", "phone5"];
  phones.forEach(index => {
    if (index == phone) {
      document.getElementById(index).classList.toggle("ihidden", false);
    } else {
      document.getElementById(index).classList.toggle("ihidden", true);
    }
  });
}
// const leaveSection = function(destination, direction) {};
//
const leaveSection1 = function(destination, direction) {
  // 0 to 1
  if (destination.index == 1) {
    // Ballon
    toggleVisible(ballon, false);
  }
};
const leaveSection2 = function(destination, direction) {
  // self command
  toggleVisible(ballon, true);
  messages_timeline.seek(messages_timeline.duration())
  if (destination.index == 2) {
    toggleVisible(left_cloud_3, false);
    toggleVisible(right_cloud_3, false);
  }
};
const leaveSection3 = function(destination, direction) {
  //self command
  toggleVisible(left_cloud_3, true);
  toggleVisible(right_cloud_3, true);
  // 2 to 1
  if (destination.index == 1) {
    // Ballon
    toggleVisible(ballon, false);
    // messages
  }
};
const leaveSection4 = function(destination, direction) {
  //self command
  //-
  routeCircleAnimation.pause();
  // 3 to 2
  if (destination.index == 2) {
    toggleVisible(left_cloud_3, false);
    toggleVisible(right_cloud_3, false);
  }
  if (destination.index == 4) {
    toggleVisible(sun_5, false);
  }
};
const leaveSection5 = function(destination, direction) {
  toggleVisible(sun_5, true);
  sun_animation.pause();
};
const loadSection2 = function(destination, direction) {
  ballon.classList.toggle("upDown", true);
  messages_timeline.play();
};
const loadSection3 = function(destination, direction) {
  left_cloud_3.classList.toggle("leftRight", true);
  right_cloud_3.classList.toggle("leftRight", true);
};
const loadSection4 = function(destination, direction) {
  routeCircleAnimation.play();
};
const loadSection5 = function(destination, direction) {
  sun_animation.play();
};
const navigationPosition = isMobile() ? "right" : "left";
const fullpage_api = new fullpage("#fullpage", {
  licenseKey: "ABEEFA3C-DF9A45ED-837D7D95-7B0E1957",
  verticalCentered: false,
  navigation: true,
  scrollingSpeed: 1200,
  navigationPosition: navigationPosition,
  afterLoad: function(origin, destination, direction) {
    if (destination.index == 1) {
      loadSection2(destination, direction);
    }
    if (destination.index == 2) {
      loadSection3(destination, direction);
    }
    if (destination.index == 3) {
      loadSection4(destination, direction);
    }
    if (destination.index === 4) {
      loadSection5(destination, direction);
    }
    tooglePhone(`phone${destination.index + 1}`);

    const nav = document.getElementById("fp-nav");
    const position = isMobile() ? "fp-right" : "fp-left";
    nav.className = `${position} nav nav--${destination.index + 1}`;
  },
  onLeave: function(origin, destination, direction) {
    if (origin.index == 0) {
      leaveSection1(destination, direction);
    }
    if (origin.index == 1) {
      leaveSection2(destination, direction);
      console.log()
    }
    if (origin.index == 2) {
      leaveSection3(destination, direction);
    }
    if (origin.index == 3) {
      leaveSection4(destination, direction);
    }
    if (origin.index == 4) {
      leaveSection5(destination, direction);
    }
  },
  afterRender: function() {
    // const nav = document.getElementById("fp-nav");
    // nav.className = `fp-left nav nav--1 123`;

    [1, 2, 3, 4, 5].forEach(index => {
      document
        .querySelector(`.section--${index}`)
        .querySelector(".top-menu")
        .classList.toggle(`top-menu--${index}`, true);
    });
  }
});
// alert(window.innerWidth)