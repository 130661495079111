import { gsap } from "gsap/all";

export class routeCicle {
  constructor() {
    const outCircle = [
      "#route-top-svg__c1_1",
      "#route-top-svg__c2_1",
      "#route-bottom-svg__c1_1",
      "#route-bottom-svg__c2_1",
      "#route-bottom-svg__c3_1"
    ];
    const circles = [
      ...outCircle,
      "#route-top-svg__c1_2",
      "#route-top-svg__p1",
      "#route-top-svg__c2_2",
      "#route-top-svg__p2",
      "#route-bottom-svg__c1_2",
      "#route-bottom-svg__p1",
      "#route-bottom-svg__c2_2",
      "#route-bottom-svg__p2",
      "#route-bottom-svg__c3_2",
      "#route-bottom-svg__p3"
    ];
    this.tlCircle = gsap
      .timeline({ repeat: -1 })
      .to(outCircle, { duration: 1.2, attr: { r: 27 } })
      .to(outCircle, { duration: 1.2, attr: { r: 37 } });
    this.tlRoute = gsap
      .timeline({ paused: true })
      .to(circles, { duration: 0, scale: 0, transformOrigin: "50% 50%" })
      .to(["#route-top-svg", "#route-bottom-svg"], {
        duration: 0.2,
        opacity: 1
      })
      .to(circles, { duration: 0.5, scale: 1 });
  }
  play() {
    this.tlRoute.restart();
    this.tlCircle.play();
  }
  pause() {
    gsap.to(["#route-top-svg", "#route-bottom-svg"], { duration: 1, opacity: 0 });
    this.tlCircle.pause();
    this.tlCircle.pause();
  }
}
